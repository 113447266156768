<template>
  <div class="writing">
    <div class="btns">
      <el-button
        v-if="$route.query.type === 'practice'"
        style="width: 129px"
        @click="practiceBack"
        type="primary"
        >返回</el-button
      >
      <el-button
        v-else-if="$route.query.type === 'history'"
        style="width: 129px"
        @click="historyBack"
        type="primary"
        >返回</el-button
      >
      <div v-else>
        <el-button
          v-if="$route.query.type === 'plan'"
          style="width: 129px"
          @click="$route.query.history ? $router.back() : $router.push('writing')"
          type="primary"
          >返回</el-button
        >
        <el-button
          v-else
          @click="pushToSubmitPage"
          :type="submitNum === 0 ? 'info' : 'primary'"
          :disabled="submitNum === 0"
          >提交作文（{{ submitNum }}）</el-button
        >
        <!-- $router.push('submitArticle') -->
        <el-button
          v-if="$route.query.type === 'plan' && !$route.query.history"
          @click="historyPlan"
          :disabled="historyShow"
          :type="historyShow ? 'info' : 'primary'"
          >历史计划</el-button
        >
        <el-button
          v-if="btnTitle === '查看计划' && !$route.query.history"
          @click="checkPlan"
          type="primary"
          >{{ btnTitle }}</el-button
        >
        <el-button
          v-else-if="btnTitle === '修改计划' && !$route.query.history"
          @click="checkPlan"
          type="primary"
          >{{ btnTitle }}</el-button
        >
        <el-button
          v-else-if="btnTitle === '设置计划' && !$route.query.history"
          @click="checkPlan"
          type="primary"
          >{{ btnTitle }}</el-button
        >
      </div>
    </div>
    <!-- 学习计划 -->
    <div v-if="$route.query.type === 'plan'">
      <calendar :stay="lastStay" :type="planType" />
    </div>
    <!-- 历史计划 -->
    <div v-else-if="$route.query.type === 'history'">
      <history
        :data="historyData"
        @changePage="historyChange"
        :total="historyTotal"
        :type="planType"
      />
    </div>
    <!-- 作文题目列表 -->
    <div v-else-if="$route.query.type === 'practice'">
      <practice :data="practiceArray" :num="submitNum" @goDetail="pushToSubmitPage" />
    </div>
    <div v-else>
      <my-table
        :columns="columns"
        :table-data="writeList"
        :handle-arr="handleArr"
        :total="writeTotal"
        @changePage="changePage"
        @handle="handle"
        emptyText=" "
      />
      <div class="noWriting" v-if="!writeList.length">
        <div class="noText">
          您还没有提交过作文，请点下面的按钮提交一篇作文吧
        </div>
        <el-button
          @click="pushToSubmitPage"
          :type="submitNum === 0 ? 'info' : 'primary'"
          :disabled="submitNum === 0"
          >提交作文（{{ submitNum }}）</el-button
        >
      </div>
    </div>
    <plan-dialog
      :visible="dialogFormVisible"
      @planDialog="planDialog"
      :planData="formData"
      :type="planType"
    ></plan-dialog>
    <followed-pub-account
      ref="pubAccount"
      @nextHandler="nextHandler"
      type="writing"
    ></followed-pub-account>
  </div>
</template>

<script>
import MyTable from "@/components/MyTable.vue";
import planDialog from "@/components/planDialog.vue";
import { connectState } from "@/utils/wyUtil";
import http from "@/utils/request";
import Calendar from "@/components/PlanCalendar.vue";
import History from "@/components/History.vue";
import Practice from "@/components/Practice.vue";
import FollowedPubAccount from "@/components/FollowedPubAccount/index.vue";

export default {
  name: "Writing",
  inject: ["reload"],
  components: {
    MyTable,
    planDialog,
    Calendar,
    History,
    Practice,
    FollowedPubAccount
  },
  data() {
    return {
      datedef: [
        { date: "2018-06-30", content: null, cid: null },
        { date: "2018-06-26", content: null, cid: null }
      ],
      prop: "date", //对应日期字段名
      columns: [
        {
          prop: "correctingCode",
          label: "作文编号",
          showToolTip: true
        },
        {
          prop: "status",
          label: "当前进度",
          style: row => {
            // (row.status === 2 ? 'color:red;' : 'color:#3377FF;')
            const obj = {
              0: "color:#E6A23C;",
              1: "color:#3377FF;",
              2: "color:#F56C6C;",
              3: "color:#3377FF;",
              4: "color:#3377FF;",
              5: "color:#3377FF;",
              6: "color:#67C23A;"
            };
            return obj[row.status];
          },
          render(row) {
            const obj = {
              0: "待批改",
              1: "批改中",
              2: "待修改",
              3: "批改中",
              4: "批改中",
              5: "批改中",
              6: "已完成"
            };
            return obj[row.status];
          }
        },
        {
          prop: "createDate",
          label: "提交时间",
          showToolTip: true
        }
      ],
      handleArr: [
        {
          text: "查看",
          color: "#6D7278"
        },
        {
          text: "修改",
          visible(row) {
            return row.status === 2;
          }
        },
        {
          text: "提问",
          visible(row) {
            return row.status === 6;
          }
          // disabled: (row) => {
          //   // 倒计时为空，就不能点击提问了
          //   if (row.answerRemainTime) {
          //     return false;
          //   }
          //   return true;
          // },
        },
        {
          text: "查看报告",
          visible(row) {
            return row.status === 6;
          }
        }
      ],
      form: {},
      curPage: 1,
      dialogFormVisible: false,
      events: [], // 活动数据
      params: {}, // 活动查询参数
      btnTitle: "", // 查看、修改、设置计划按钮标题
      planId: null, // 当前计划ID
      formData: {}, // 修改计划时，表单的值
      period: "", // 所处阶段
      firPeriod: false, // 预备期
      secPeriod: false, // 热身期
      thPeriod: false, // 进阶期
      fouPeriod: false, // 冲刺期
      practiceArray: [], // 作文题目列表数据
      text: "", // 写作批改页表格按钮
      lastStay: null,
      historyData: [], // 历史计划数组
      historyShow: false,
      historyTotal: 0,
      planType: 0,
      routePayload: null
    };
  },
  computed: {
    ...connectState("writing", ["submitNum", "writeList", "writeTotal"])
  },
  async created() {
    this.$store.dispatch("writing/getSubmitNum");
    this.getList();
    this.getStudyPlan();
    this.getTitleList();
  },
  watch: {
  
    $route(to, from) {
      // 从作文列表返回时，带上上次停留页面的日期
      if (Object.keys(from.params).length) {
        this.lastStay = from.params.lastStay;
        this.btnTitle = "修改计划";
      } else {
        this.reload();
      }
    }
  },
  methods: {
    // 学习计划是否展示
    async getStudyPlan() {
      const res = await http(`/hcp/base/base/dict/studyPlanSwitch/writing`, {
        method: "GET"
      });
      if (Number(res)) {
        const res1 = await http(`/hcp/yida/studyPlan/getUserLastPlanId/0`, {
          method: "GET"
        });
        if (res1) {
          this.btnTitle = "查看计划";
          if (this.$route.query.type == "plan") {
            this.btnTitle = "修改计划";
          }
          this.planId = res1;
        } else {
          this.btnTitle = "设置计划";
        }
        // 是否展示历史计划按钮
        this.getHistoryList();
      }
    },
    // 获取作文题目列表数据
    async getTitleList() {
      if (this.$route.query.type === "practice") {
        const res = await http(
          `/hcp/yida/studyPlan/getQuestionsByDetailId/${this.$route.query.id}`,
          {
            method: "GET"
          }
        );
        this.practiceArray = res;
      }
    },
    // 题目列表返回
    practiceBack() {
      this.$route.params.lastStay = this.$route.query.date;
      this.$router.back();
    },
    show(ids) {
      // 作文题目展示
      this.displayArray[ids].isHide = false;
      this.displayArray[ids].isShow = true;
    },
    hide(ids) {
      // 作文题目收起
      this.displayArray[ids].isHide = true;
      this.displayArray[ids].isShow = false;
    },
    toPractice(item, pid, status) {
      this.$router.push({
        path: `/submitArticle`,
        query: { id: item, pid: pid, count: this.submitNum, status: status }
      });
    },
    getList() {
      this.$store.dispatch("writing/getWriteList", { curPage: this.curPage });
    },
    changePage(page) {
      this.curPage = page;
      this.getList();
    },
    historyChange(page) {
      this.curPage = page;
      this.getHistoryList();
    },
    handle(text, row) {
      if (text === "查看" || text === "提问") {
        this.$router.push(`/articleInfo/${row.correctingId}?debug=true`);
      } else if (text === "修改") {
        this.$router.push(`/submitArticle/?correctingId=${row.correctingId}`);
      } else {
        this.$router.push(`/writingReport/${row.correctingId}`);
      }
    },
    // 查看计划
    async checkPlan() {
      if (this.btnTitle == "查看计划") {
        this.$router.push({
          path: "/writing",
          query: { type: "plan", planId: this.planId }
        });
      }
      if (this.btnTitle == "修改计划") {
        if (this.$route.query.planId) {
          const res2 = await http(
            `/hcp/yida/studyPlan/getStudyPlanInfo/${this.$route.query.planId}`,
            {
              method: "GET"
            }
          );
          this.formData = res2;
          this.dialogFormVisible = true;
        }
      }
      if (this.btnTitle == "设置计划") {
        this.dialogFormVisible = true;
      }
    },
    planDialog(data) {
      this.dialogFormVisible = data;
    },
    async getHistoryList() {
      let limit = 10;
      const res = await http(
        `/hcp/yida/studyPlan/getUserHistoryStudyPlanPage?type=0&curPage=${this.curPage}&limit=${limit}`,
        {
          method: "GET"
        }
      );
      if (res.total !== 0) {
        this.historyData = res.list;
        this.historyTotal = res.total;
      } else {
        this.historyShow = true;
      }
    },
    // 历史计划
    async historyPlan() {
      this.$router.push({
        path: "/writing",
        query: { type: "history" }
      });
    },
    historyBack() {
      this.$router.back();
    },
    nextHandler() {
      if (this.$route.query.type === "practice") {
        this.$router.push({
          path: "/submitArticle",
          query: this.routePayload
        });
        return false;
      }
      this.$router.push("submitArticle");
    },
    async pushToSubmitPage(payload) {
      var b = window.localStorage.getItem("isOpen");
      var c = JSON.parse(b); // 将 JSON 字符串转成对象
      const type = this.$route.query.type ? this.$route.query.type : "submit";
      if (type === "practice") {
        this.routePayload = payload;
      }
      var isSubmitFirstOpen = JSON.parse(window.localStorage.getItem("isSubmitFirstOpen"));
      var isPracticeFirstOpen = JSON.parse(window.localStorage.getItem("isPracticeFirstOpen"));
      const canShow = await this.$store.dispatch("oral/canShowPubAccount");
      if (canShow) {
        if (c) {
          const isOpen = c.isOpen === "0";
          const time = c.time;
          const date = c.date;
          if (parseInt(time) + parseInt(date) < new Date().getTime() / 1000) {
            // 如果 date + time 小于当前时间的时间戳，说明已经超过24小时的时效时间
            window.localStorage.removeItem("isSubmitFirstOpen");
            window.localStorage.removeItem("isPracticeFirstOpen");
            this.$refs.pubAccount.handleOpen(type);
            return false;
          } else {
            if (type === "practice") {
              if (!isPracticeFirstOpen) {
                this.$refs.pubAccount.handleOpen(type);
                return false;
              }
            } else {
              if (!isSubmitFirstOpen) {
                this.$refs.pubAccount.handleOpen(type);
                return false;
              }
            }
            if (c && isOpen) {
              // Do something with return value
              this.nextHandler();
              return true;
            }
          }
        } else {
          window.localStorage.removeItem("isOralFirstOpen");
          window.localStorage.removeItem("isOralStageFirstOpen");
          window.localStorage.removeItem("isOralPlanOpen");
          window.localStorage.removeItem("isSubmitFirstOpen");
          window.localStorage.removeItem("isPracticeFirstOpen");
          this.$refs.pubAccount.handleOpen(type);
          return false;
        }
      } else {
        this.nextHandler();
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.writing {
  position: relative;

  .btns {
    display: flex;
    // justify-content: space-between;
    margin-bottom: 20px;
  }

  .el-button {
    font-weight: bold;
  }

  .noWriting {
    color: #cacdd6;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);

    .noText {
      margin: 80px 0 40px;
    }
  }
}
</style>
